.App {
  text-align: center;
  background: rgb(70, 84, 156);
  background: linear-gradient(
    180deg,
    rgb(96, 120, 238) 0%,
    rgb(103, 49, 179) 49%,
    rgb(170, 23, 184) 87%
  );
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #cdeac0;
}

.Floating-form {
  /* backdrop-filter: blur(20px); */
  display: flex;
  flex-direction: column;
  min-height: 2vh;
  background-color: rgba(220, 225, 224, 0.304);
  color: black;
  max-width: 310px;
  min-width: 300px;
  border-radius: 1vh;
  padding: 2%;
  overflow: hidden;
}

button {
  padding: 1vh;
  margin: 1vh;
  outline: none;
  background-color: #e9c46a;
  border-radius: 1vh;
}

button:hover {
  background-color: #00000010;
  border-radius: 1vh;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2vh;
  padding-right: 2vh;
}

p {
  font-size: medium;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid-item-clue {
  width: 50vh;
}

h2 {
  margin: 0;
}

.teamname-input {
  width: auto;
}

.gamepin-input {
  width: auto;
}

h1.game-welcome-text:has(.input100) {
  margin-bottom: 50px;
  color: red;
}

h1.game-welcome-text {
  color: #ffffffb7;
  font-size: 30px;
  margin-bottom: 20px;
}
