.containerAdmin {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: auto;
}

.form {
  flex: 2;
  margin-right: 2.5%;
}

.adminClues {
  flex: 1;
  margin-left: 2.5%;
  overflow: auto;
  max-height: 270px;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

img {
  width: 80%;
}

.cancel-add-clue,
.submit-add-clue {
  max-height: 100px;
  height: 60px;
  align-items: center;
}

.Floating-form {
  max-width: 700px;
  width: 300px;
  min-width: 300px;
}

.cancel-add-new-card-btn,
.add-new-card-btn {
  padding: 2px;
  font-size: 14px;
  outline: none !important;
}

.focus-input100-game-pin::after {
  color: #ffffffb7;
}

#root {
  overflow: hidden;
}
