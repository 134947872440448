.container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.left {
  flex: 2;
}

.right {
  flex: 1;
}

.teams {
  flex: 1;
  margin-right: 2.5%;
  height: 50vh;
  overflow: auto;
}

.clueContainer {
  flex: 2;
  margin-left: 2.5%;
  overflow: auto;
}

.dashboardClues {
  height: 45vh;
  overflow: auto;
}
