.Game {
  text-align: center;
  background: rgb(38, 70, 83);
  background: linear-gradient(
    180deg,
    rgba(38, 70, 83, 1) 0%,
    rgba(231, 111, 81, 1) 70%,
    rgba(233, 196, 106, 1) 100%
  );
  min-height: 100vh;
}

input.input100 {
  all: initial;
}
.clue {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  justify-content: center;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.form {
  flex: 3;
  height: 100%;
}

.flipButton {
  flex: 1;
  height: 25%;
  width: 100%;
}

.game-welcome {
  margin-bottom: 10px;
  position: relative;
  top: -5px;
  padding: 0px;
  left: 11px;
}
.game-welcome-header {
  font-weight: 700;
  font-size: small;
  color: #ffffff;
}

.camera {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.container-cluecard .container {
  justify-content: center !important;
}
